body {
  margin:0;
	padding:0;
	height:100%;
	overflow: scroll;
	background-color: #333;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
	background: url(./images/bg.png) repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
