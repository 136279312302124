@font-face {
    font-family: 'Clementine';
    src: url('../../fonts/clementine.ttf') format('truetype');
}

@font-face {
    font-family: 'Chalkboard';
    src: url('../../fonts/chalkboard.ttf') format('truetype');
}

@font-face {
    font-family: 'Chalk Outline';
    src: url('../../fonts/chalk-outline.ttf') format('truetype');
}

@font-face {
    font-family: 'White Chalk';
    src: url('../../fonts/white-chalk.ttf') format('truetype');
}

@font-face {
    font-family: 'kg-second-chances';
    src: url('../../fonts/kg-second-chances.ttf') format('truetype');
}

@font-face {
    font-family: 'cf-blackboard';
    src: url('../../fonts/cf-blackboard.ttf') format('truetype');
}

@font-face {
    font-family: 'kg-sketch';
    src: url('../../fonts/kg-sketch.ttf') format('truetype');
}

.borderClass {
    border-bottom:solid 3px white;
    border-bottom-right-radius: 225px 5px;
    border-bottom-left-radius: 225px 5px;
    width: 90%;
}

.App-header {
    display: flex;
    flex-direction: column;
    min-height: 5vh;
    font-size: calc(15px + 2vmin);
}

.title {
    font-size: 3.2em;
    color: #fff;
    margin-top: 20px;
}

title:hover {
    text-decoration: underline;
}

.itemDouble {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin-right: 20px;
    margin-bottom: 30px;
    font-weight: 100;
    font-family: 'Chalkboard', sans-serif;
    font-size: 1.3em;
    border-style: none;
    
}

.itemSearch {
    margin-right: 20px;
}

.itemSearch:hover {
    text-decoration: underline;
}

.itemFavorites {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.itemFavorites:hover {
    text-decoration: underline;
}

.itemLogin {
    margin-right: 20px;
}

.itemLogin:hover {
    text-decoration: underline;
}

.numberOfItems {
    font-size: large;
    font-weight: 900;
    border-style: solid;
    background-color: rgb(223, 0, 0);
    border-radius: 50%;
    width: 22px;
    height: 22px;
}

@media all and (min-width: 700px) {
    .itemFavorites {
        margin-right: 90px;
        margin-left: 60px;
    }
  }