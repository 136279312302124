@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Quicksand:wght@300&display=swap');

.Hyph {
    margin-right: 5px;
    margin-left: 5px;
  }
  
  .Input,
  .Textarea {
    border: 1px solid #ddd;
    display: block;
    font-size: 1.1em;
    margin-bottom: 10px;
    padding: 10px;
    text-align: left;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  .Input::placeholder,
  .Textarea::placeholder {
    color: black;
    font-size: 0.4em;
  }
  
  .Textarea {
    resize: vertical;
}

  @font-face {
    font-family: 'Clementine';
    src: url('../../fonts/clementine.ttf') format('truetype');
}

@font-face {
    font-family: 'Chalkboard';
    src: url('../../fonts/chalkboard.ttf') format('truetype');
}

@font-face {
    font-family: 'Chalk Outline';
    src: url('../../fonts/chalk-outline.ttf') format('truetype');
}

@font-face {
    font-family: 'White Chalk';
    src: url('../../fonts/white-chalk.ttf') format('truetype');
}

@font-face {
    font-family: 'kg-second-chances';
    src: url('../../fonts/kg-second-chances.ttf') format('truetype');
}

@font-face {
    font-family: 'cf-blackboard';
    src: url('../../fonts/cf-blackboard.ttf') format('truetype');
}

@font-face {
    font-family: 'kg-sketch';
    src: url('../../fonts/kg-sketch.ttf') format('truetype');
}

  .Button {
    background: none;
    border: solid;
    border-style: double;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 100px 15px;
    border-bottom-left-radius:15px 100px;
    color: white;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 1.2em;
    margin: 4px 2px;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 100;
    font-family: 'Chalkboard', sans-serif;
  }

  .Button:hover {
    text-decoration: underline;
    cursor: pointer;
}

  .Required {
    color: #CA1551;
    font-family: Arial;
    font-weight: bold;
  }
  
  .Section {
    margin-left: 20px;
    margin-right: 20px;
  }
  .Section--list {
    margin-left: 10px;
    margin-right: 10px;
  }
  
