.username {
    margin-top: 10px;
}

.password {
    margin-top: 20px;
    margin-bottom: 20px;
}

::placeholder {
    text-align: left;
    font-weight: 100;
}

.errorMessage {
    color: red;
}

.successMessage {
    color: rgb(28, 212, 21);
}