@font-face {
  font-family: 'Clementine';
  src: url('fonts/clementine.ttf') format('truetype');
}

@font-face {
  font-family: 'Chalkboard';
  src: url('fonts/chalkboard.ttf') format('truetype');
}

@font-face {
  font-family: 'Chalk Outline';
  src: url('fonts/chalk-outline.ttf') format('truetype');
}

@font-face {
  font-family: 'White Chalk';
  src: url('fonts/white-chalk.ttf') format('truetype');
}

@font-face {
  font-family: 'kg-second-chances';
  src: url('fonts/kg-second-chances.ttf') format('truetype');
}

@font-face {
  font-family: 'cf-blackboard';
  src: url('fonts/cf-blackboard.ttf') format('truetype');
}

@font-face {
  font-family: 'kg-sketch';
  src: url('fonts/kg-sketch.ttf') format('truetype');
}

.App {
  text-align: center;
  font-family: 'Chalkboard', sans-serif;
  color: white;
  font-size: 2em;
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.mainComponents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}