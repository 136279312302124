@import url(https://fonts.googleapis.com/css2?family=Caveat&family=Quicksand:wght@300&display=swap);
body {
  margin:0;
	padding:0;
	height:100%;
	overflow: scroll;
	background-color: #333;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
	background: url(/static/media/bg.22d74019.png) repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Clementine';
  src: url(/static/media/clementine.8146c22c.ttf) format('truetype');
}

@font-face {
  font-family: 'Chalkboard';
  src: url(/static/media/chalkboard.f5f01ea8.ttf) format('truetype');
}

@font-face {
  font-family: 'Chalk Outline';
  src: url(/static/media/chalk-outline.7a5121a9.ttf) format('truetype');
}

@font-face {
  font-family: 'White Chalk';
  src: url(/static/media/white-chalk.02a3d769.ttf) format('truetype');
}

@font-face {
  font-family: 'kg-second-chances';
  src: url(/static/media/kg-second-chances.d0b09c4e.ttf) format('truetype');
}

@font-face {
  font-family: 'cf-blackboard';
  src: url(/static/media/cf-blackboard.6e2a049c.ttf) format('truetype');
}

@font-face {
  font-family: 'kg-sketch';
  src: url(/static/media/kg-sketch.7a4c79b5.ttf) format('truetype');
}

.App {
  text-align: center;
  font-family: 'Chalkboard', sans-serif;
  color: white;
  font-size: 2em;
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.mainComponents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.Hyph {
    margin-right: 5px;
    margin-left: 5px;
  }
  
  .Input,
  .Textarea {
    border: 1px solid #ddd;
    display: block;
    font-size: 1.1em;
    margin-bottom: 10px;
    padding: 10px;
    text-align: left;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  .Input::-webkit-input-placeholder, .Textarea::-webkit-input-placeholder {
    color: black;
    font-size: 0.4em;
  }
  
  .Input::placeholder,
  .Textarea::placeholder {
    color: black;
    font-size: 0.4em;
  }
  
  .Textarea {
    resize: vertical;
}

  @font-face {
    font-family: 'Clementine';
    src: url(/static/media/clementine.8146c22c.ttf) format('truetype');
}

@font-face {
    font-family: 'Chalkboard';
    src: url(/static/media/chalkboard.f5f01ea8.ttf) format('truetype');
}

@font-face {
    font-family: 'Chalk Outline';
    src: url(/static/media/chalk-outline.7a5121a9.ttf) format('truetype');
}

@font-face {
    font-family: 'White Chalk';
    src: url(/static/media/white-chalk.02a3d769.ttf) format('truetype');
}

@font-face {
    font-family: 'kg-second-chances';
    src: url(/static/media/kg-second-chances.d0b09c4e.ttf) format('truetype');
}

@font-face {
    font-family: 'cf-blackboard';
    src: url(/static/media/cf-blackboard.6e2a049c.ttf) format('truetype');
}

@font-face {
    font-family: 'kg-sketch';
    src: url(/static/media/kg-sketch.7a4c79b5.ttf) format('truetype');
}

  .Button {
    background: none;
    border: solid;
    border-style: double;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 100px 15px;
    border-bottom-left-radius:15px 100px;
    color: white;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 1.2em;
    margin: 4px 2px;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 100;
    font-family: 'Chalkboard', sans-serif;
  }

  .Button:hover {
    text-decoration: underline;
    cursor: pointer;
}

  .Required {
    color: #CA1551;
    font-family: Arial;
    font-weight: bold;
  }
  
  .Section {
    margin-left: 20px;
    margin-right: 20px;
  }
  .Section--list {
    margin-left: 10px;
    margin-right: 10px;
  }
  

@font-face {
    font-family: 'Clementine';
    src: url(/static/media/clementine.8146c22c.ttf) format('truetype');
}

@font-face {
    font-family: 'Chalkboard';
    src: url(/static/media/chalkboard.f5f01ea8.ttf) format('truetype');
}

@font-face {
    font-family: 'Chalk Outline';
    src: url(/static/media/chalk-outline.7a5121a9.ttf) format('truetype');
}

@font-face {
    font-family: 'White Chalk';
    src: url(/static/media/white-chalk.02a3d769.ttf) format('truetype');
}

@font-face {
    font-family: 'kg-second-chances';
    src: url(/static/media/kg-second-chances.d0b09c4e.ttf) format('truetype');
}

@font-face {
    font-family: 'cf-blackboard';
    src: url(/static/media/cf-blackboard.6e2a049c.ttf) format('truetype');
}

@font-face {
    font-family: 'kg-sketch';
    src: url(/static/media/kg-sketch.7a4c79b5.ttf) format('truetype');
}

.allElements {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 70px;
}

::-webkit-input-placeholder {
    text-align: center;
    font-family: 'kg-second-chances', sans-serif;
    font-weight: 900;
    letter-spacing: 1px;
}

::placeholder {
    text-align: center;
    font-family: 'kg-second-chances', sans-serif;
    font-weight: 900;
    letter-spacing: 1px;
}

.ingredientInput{
    justify-content: center;
    width: 90%;
    margin-left: 5px;
}

.ingredientInput, .Input ul {
    text-align: left;
    padding: 0;
} 

.ingredientInput ul::before {
    content: "";
    border-top: 1px solid grey;
}

.ingredientInput li {
    list-style-type: none;
    padding: 10px 5px;
    cursor: pointer;
}

.ingredientInput li:hover {
    text-decoration: underline;
}

.autoCompleteSuggestions {
    font-size: 1em;
}

.searchRecipeButton {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 1em;
}

.ingredientOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.selectedIngredients ul {
    font-size: 1em;
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding: 0;
} 

.selectedIngredients ul::before {
    content: "";
}

.selectedIngredients li {
    padding: 10px 5px;
}

.recipeTitle ul {
    letter-spacing: 1px;
    list-style-type: none;
    margin: 0;
    padding: 0;
} 

.recipeTitle ul::before {
    content: "";
}

.recipeTitle li {
    padding: 10px 5px;
    cursor: pointer;
}

.recipeTitle li:hover {
    text-decoration: underline;
}

.Note__delete{
    background: none;
    border: solid;
    border-style: double;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 225px 15px;
    border-bottom-left-radius:15px 255px;
    color: white;
    margin-left: 40px;
    font-family: 'Chalkboard', sans-serif;
    font-size: 1em;
}

.Note__delete:hover {
    background-color: #bd0909;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.saveFavoritesButton {
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 1em;
}

.saveFavoritesButton:hover {
    cursor: pointer;
}

img {
    border-radius: 20px;
}
.welcomeLanding {
    opacity: 0;
    -webkit-animation: welcomeAnimationText 3s;
            animation: welcomeAnimationText 3s;
}

@-webkit-keyframes welcomeAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes welcomeAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}


.searchLanding {
    opacity: 0;
    -webkit-animation: searchAnimationText 3s;
            animation: searchAnimationText 3s;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

@-webkit-keyframes searchAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes searchAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

.favoritesLanding {
    opacity: 0;
    -webkit-animation: favoritesAnimationText 3s;
            animation: favoritesAnimationText 3s;
    -webkit-animation-delay: 6s;
            animation-delay: 6s;
}

@-webkit-keyframes favoritesAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes favoritesAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

.startSearchLanding {
    opacity: 0;
    -webkit-animation: searchFeature 3s forwards;
            animation: searchFeature 3s forwards;
    -webkit-animation-delay: 9s;
            animation-delay: 9s;
}

@-webkit-keyframes searchFeature {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes searchFeature {
    from {opacity: 0;}
    to {opacity: 1;}
}
@font-face {
    font-family: 'Clementine';
    src: url(/static/media/clementine.8146c22c.ttf) format('truetype');
}

@font-face {
    font-family: 'Chalkboard';
    src: url(/static/media/chalkboard.f5f01ea8.ttf) format('truetype');
}

@font-face {
    font-family: 'Chalk Outline';
    src: url(/static/media/chalk-outline.7a5121a9.ttf) format('truetype');
}

@font-face {
    font-family: 'White Chalk';
    src: url(/static/media/white-chalk.02a3d769.ttf) format('truetype');
}

@font-face {
    font-family: 'kg-second-chances';
    src: url(/static/media/kg-second-chances.d0b09c4e.ttf) format('truetype');
}

@font-face {
    font-family: 'cf-blackboard';
    src: url(/static/media/cf-blackboard.6e2a049c.ttf) format('truetype');
}

@font-face {
    font-family: 'kg-sketch';
    src: url(/static/media/kg-sketch.7a4c79b5.ttf) format('truetype');
}

.borderClass {
    border-bottom:solid 3px white;
    border-bottom-right-radius: 225px 5px;
    border-bottom-left-radius: 225px 5px;
    width: 90%;
}

.App-header {
    display: flex;
    flex-direction: column;
    min-height: 5vh;
    font-size: calc(15px + 2vmin);
}

.title {
    font-size: 3.2em;
    color: #fff;
    margin-top: 20px;
}

title:hover {
    text-decoration: underline;
}

.itemDouble {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin-right: 20px;
    margin-bottom: 30px;
    font-weight: 100;
    font-family: 'Chalkboard', sans-serif;
    font-size: 1.3em;
    border-style: none;
    
}

.itemSearch {
    margin-right: 20px;
}

.itemSearch:hover {
    text-decoration: underline;
}

.itemFavorites {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.itemFavorites:hover {
    text-decoration: underline;
}

.itemLogin {
    margin-right: 20px;
}

.itemLogin:hover {
    text-decoration: underline;
}

.numberOfItems {
    font-size: large;
    font-weight: 900;
    border-style: solid;
    background-color: rgb(223, 0, 0);
    border-radius: 50%;
    width: 22px;
    height: 22px;
}

@media all and (min-width: 700px) {
    .itemFavorites {
        margin-right: 90px;
        margin-left: 60px;
    }
  }
.deleteButton {
    margin-top: 5px;
    margin-bottom: 40px;
}
.username {
    margin-top: 10px;
}

.password {
    margin-top: 20px;
    margin-bottom: 20px;
}

::-webkit-input-placeholder {
    text-align: left;
    font-weight: 100;
}

::placeholder {
    text-align: left;
    font-weight: 100;
}

.errorMessage {
    color: red;
}

.successMessage {
    color: rgb(28, 212, 21);
}
.loginText {
    letter-spacing: 3px;
    margin: 0 0 10px 0;
}

.needAccountButton {
    margin-top: 20px;
}
.errorMessage {
    color: red;
}

.successMessage {
    color: rgb(28, 212, 21);
}
.createAccountText {
    letter-spacing: 3px;
    margin: 0 0 10px 0;
}
.landingFormClass{
    margin-top: 20px;
}

.loginLanding {
    margin-top: 20px;
    opacity: 0;
    -webkit-animation: loginLandingText 3s forwards;
            animation: loginLandingText 3s forwards;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
}

@-webkit-keyframes loginLandingText {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes loginLandingText {
    from {opacity: 0;}
    to {opacity: 1;}
}
