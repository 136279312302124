.landingFormClass{
    margin-top: 20px;
}

.loginLanding {
    margin-top: 20px;
    opacity: 0;
    animation: loginLandingText 3s forwards;
    animation-delay: 0.5s;
}

@keyframes loginLandingText {
    from {opacity: 0;}
    to {opacity: 1;}
}