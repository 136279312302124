.welcomeLanding {
    opacity: 0;
    animation: welcomeAnimationText 3s;
}

@keyframes welcomeAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}


.searchLanding {
    opacity: 0;
    animation: searchAnimationText 3s;
    animation-delay: 3s;
}

@keyframes searchAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

.favoritesLanding {
    opacity: 0;
    animation: favoritesAnimationText 3s;
    animation-delay: 6s;
}

@keyframes favoritesAnimationText {
    from {opacity: 0;}
    to {opacity: 1;}
}

.startSearchLanding {
    opacity: 0;
    animation: searchFeature 3s forwards;
    animation-delay: 9s;
}

@keyframes searchFeature {
    from {opacity: 0;}
    to {opacity: 1;}
}